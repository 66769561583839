import React from 'react';
import { Link } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { external, left, right, tags, content, imageWrapper, project } from '../components/project/project.module.css';
import GithubIcon from '../components/svg/githubIcon';
import LinkIcon from '../components/svg/linkIcon';
import DocumentIcon from '../components/svg/documentIcon';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { meta } from 'eslint/lib/rules/*';

interface Props {
    side;
    metadata: {
        slug;
        title;
        description;
        thumbnail;
        tags;
        githubUrl;
        documentUrl;
        externalUrl;
    };
}

const ProjectComponent: React.FunctionComponent<Props> = ({ side, metadata }: Props) => {
    const thumbnail = getImage(metadata.thumbnail);
    const tagStrings = metadata.tags.split(',');
    const ghIcon =
        metadata.githubUrl != undefined ? (
            <OutboundLink href={metadata.githubUrl}>
                <GithubIcon />
            </OutboundLink>
        ) : (
            ''
        );
    const docIcon =
        metadata.documentUrl != undefined ? (
            <OutboundLink href={metadata.documentUrl}>
                <DocumentIcon />
            </OutboundLink>
        ) : (
            ''
        );
    const linkIcon =
        metadata.externalUrl != undefined ? (
            <OutboundLink href={metadata.externalUrl}>
                <LinkIcon />
            </OutboundLink>
        ) : (
            ''
        );
    const tagTags = (
        <ul className={tags}>
            {tagStrings.map((element) => (
                <li key={element}>{element}</li>
            ))}
        </ul>
    );
    const info = (
        <div className={content}>
            <div>
                {/* <Link to={data.slug}> */}
                <h3>{metadata.title}</h3>
                {/* </Link> */}
                <p>{metadata.description}</p>
            </div>
            {tagTags}
            <div className={external}>
                {ghIcon}
                {docIcon}
                {linkIcon}
            </div>
        </div>
    );
    const cont =
        side === 'left' ? (
            <article className={`${left} ${project}`} key={metadata.slug}>
                {/* <GatsbyImage className={imageWrapper} image={thumbnail} alt="sad" /> */}
                {info}
            </article>
        ) : (
            <article className={`${right} ${project}`} key={metadata.slug}>
                {info}
                {/* <GatsbyImage
                    style={{ position: 'relative', zIndex: -1 }}
                    className={imageWrapper}
                    image={thumbnail}
                    alt="sad"
                /> */}
            </article>
        );
    return cont;
};

export default ProjectComponent;
