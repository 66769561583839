import React from 'react';
import Menu from '../components/menu';
import Project from '../components/project';
import { graphql } from 'gatsby';

interface Props {
    data: {
        remarks: {
            edges: [
                {
                    node: {
                        frontmatter: {
                            slug;
                            title;
                            description;
                            thumbnail;
                        };
                    };
                },
            ];
        };
    };
}

const WorkPageComponent: React.FunctionComponent<Props> = ({ data }: Props) => {
    let count = 0;
    return (
        <div className="application">
            <Menu />
            <div className="page-container">
                <section className="work-cat-section">
                    {data.remarks.edges.map((element) => {
                        const side = count % 2 === 0 ? 'left' : 'right';
                        count++;
                        return (
                            <Project
                                key={element.node.frontmatter.slug}
                                side={side}
                                metadata={element.node.frontmatter}
                            />
                        );
                    })}
                </section>
            </div>
        </div>
    );
};

export default WorkPageComponent;

export const query = graphql`
    query {
        remarks: allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, limit: 1000) {
            edges {
                node {
                    frontmatter {
                        slug
                        title
                        description
                        tags
                        thumbnail {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        githubUrl
                        externalUrl
                        documentUrl
                    }
                }
            }
        }
    }
`;
